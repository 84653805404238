.terms {
  text-align: center;
  color: #fff;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 10px;
}

.terms a {
  color: #fff;
}
