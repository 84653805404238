.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 50px 10px;
  box-sizing: border-box;
  width: 100%;
}

.logo {
  height: 80px;
  max-width: 50%;
}

.text {
  margin-bottom: 0;
  color: #888;
  font-weight: 500;
  font-size: 18px;
  margin: 10px 0;
  text-align: center;
}
