.resolvedWrapper {
  display: flex;
  align-items: center;

}

.resolvedCheck {
  color: #66cda3;
  margin-right: 4px;
  font-size: 18px;
  font-weight: bold;
}

.resolvedText {
  font-size: 14px;
}
