@import '@sass-variables';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  background: $purple;
}

.content {
  width: 300px;
  max-width: 100%;
  padding: 30px;
  background: $purple;
  border-radius: 4px;
}

.content > form {
  display: flex;
  flex-direction: column;
}

.button {
  margin-top: 20px;
  height: 44px;
}

.button > * {
  font-size: 13px !important;
}
.header {
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.title {
  font-size: 26px;
}

.margin {
  margin-bottom: 30px;
}

.subtitle {
  margin-top: 10px;
  font-size: 16px;
}

.logo {
  max-width: 100%;
  height: 80px;
  margin: 10px auto 15px 0;
}

.header p {
  margin: 14px 0 35px 0;
}


.link {
  text-align: center;
  color: #fff;
  text-decoration: none;
  margin-top: 18px;
  font-size: 15px;
}

.link:hover {
  text-decoration: underline;
}

@media (min-width: 1024px) {
  .container {
    background: none;
  }
  .content {
    border: 4px solid $purple;
    box-shadow: 0 1px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  }
}
