.container {
  /*height: 6.8em*/;
  z-index: 9999;
  cursor: pointer;
}

.ground {
  /*height: 6.8em*/;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
