@font-face {
  font-family: 'Prenton';
  src: local('Prenton'), url(./assets/fonts/Prenton-RP-Pro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Prenton';
  font-weight: 500;
  src: local('Prenton'), url(./assets/fonts/Prenton-RP-Pro-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Prenton';
  font-weight: 600;
  src: local('Prenton'), url(./assets/fonts/Prenton-RP-Pro-Bold.otf) format('opentype');
}



body {
  margin: 0;
  position: relative;
  font-family: Prenton;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

body > #root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #efefef;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.selectable {
  transition: all .2s ease-in-out;
  cursor: pointer;
}

.selectable:active,.selectable:focus,.selectable:hover,.darken {
  filter: brightness(80%);
}
.hidden {
  position: absolute !important;
  display: none !important;
}

button,input,select,textarea {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


button {
  background: none;
  outline: none;
  border: none;
}


input,select,textarea {
  background: none;
  outline: none;
  width: 100%;
  max-width: 800px;
  font-size: 16px;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #ddd;
  min-width: 60px;
}
input,select {
  height: 42px;
  padding: 0 14px;
}

textarea {
  padding: 14px;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 32px 0 14px;
  background-image: url(@assets/images/dropdown.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 50%;
}

select::-ms-expand {
  display: none;
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 8px;
}

h1,p {
  margin: 0;
}



h1 {
  font-size: 26px;
}
