.flex {
  display: flex;
  align-items: center;
}

.imageWrapper {
  width: 67.5px;
}

.image {
  height: 90px;
  width: 67.5px;
  margin-right: 10px;
  background: #a7a7a7;
}
