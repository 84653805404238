@import '@sass-variables';

.container {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 24px;
  font-size: 5px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 17px;
}

.container input {
  width: 23px;
  height: 2px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dfdfdf;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 17px;

}

.slider:before {
  position: absolute;
  content: "";
  border-radius: 50%;
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $purple;
}

input:focus + .slider {
  box-shadow: 0 0 1px $purple;
}

input:disabled + .slider {
  cursor: default;
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}
@media (max-width: 867px) {
  .container {
    margin-top: 0;
  }
}
