@import '@sass-variables';

.grow {
  height: 60vh;
  width: 100%;
}
.modal {
  max-width: 100%;
}
.listModal {
  width: 1000px;
}
.formModal {
  width: 600px;
}
.subheader {
  padding-bottom: 15px;
  border-bottom: 1px solid #dfdfdf;
}

.width {
  width: 100%;
}

.library {
  background: #fff;
  transition: all .2s ease-in-out;
  cursor: pointer;
  color: #000;
  text-decoration: none;
}



.library:active,.library:focus,.library:hover {
  background: #dfdfdf;
}

.left {
  text-align: left;
}

.subtext {
  font-size: 16px;
  line-height: 1.8;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;
}
.subtext a,.subtext button {
  color: $purple;
  text-decoration: underline;
  cursor: pointer;
}

.buttons {
  margin-top: 45px;
  display: flex;
  justify-content: center;
}
