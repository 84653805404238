@import '@sass-variables';

.container {
  width: 100%;
}

.paginatedButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
}

.paginatedButtons > * {
  width: 28px;
  cursor: pointer;
  margin: 0 5px;
}

.wingButton {
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
}

.wingButton.disabledPageButton {
  color: #444;
  cursor: default;
}


.paginatedPageButton {
  font-size: 15px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.paginatedPageButton.activePageButton,.paginatedPageButton:hover {
  background: $purple;
  color: #fff;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .paginatedButtons > * {
    width: 35px;
  }
  .wingButton {
    font-size: 28px;
  }
  .paginatedPageButton {
    font-size: 16px;
    height: 35px;
  }
}
