.modal {
  padding: 0;
  max-width: 300px;
}

.text {
  padding: 20px;
  text-align: center;
  font-size: 16px;
}
.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttons > * {
  padding: 14px 14px;
  background: #fff;
  font-size: 14px;
  white-space: nowrap;
  transition: all .2s ease-in-out;
  cursor: pointer;
  display: block;
  color: #000;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: 500;
}


.buttons > *:not(:last-child) {
  border-bottom: 1px solid #dfdfdf;
}


.buttons > *:active,.buttons > *:focus,.buttons > *:hover {
  background: #dfdfdf;
}
