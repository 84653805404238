.container {
  padding: 10px 0;
  border-top: 2px solid #dbdbdb;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.container.collapsible .header {
  cursor: pointer;
}

.container.collapsible .content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease-in-out;
}

.padding {
  padding: 20px 0;
}

.container.collapsible.open .content {
  max-height: 800px;
}


.toggle {
  width: 35px;
  transition: transform 0.2s ease-in-out;
}

.container.open .toggle {
  transform: rotateX(180deg);
}

.container.open .toggle {
  transform: rotateX(180deg);
}
