.table::-webkit-scrollbar {
  width: 12px;
}

.table::-webkit-scrollbar-track {
  background: none;
}

.table::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 6px;
}
.table::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.header {
  border-bottom: 1px solid #dfdfdf;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  transform: scale(0.6);
  border-bottom: none;
}
