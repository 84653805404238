@import '@sass-variables';

.container {
  position: relative;
  width: 100%;
}

.hidden {
  position: absolute;
  visibility: hidden;
}

.dropzone {
  background-color: #dadada;
  border: 1px solid $purple;
  border-radius: 4px;
  margin-bottom: 22px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
}

.image {
  width: 100%;
  height: 100%;
}

.dropzone.active,.dropzone::active,.dropzone::focus {
  outline: none;
  border: 1px solid $purple;
}

.requirements {
  margin-bottom: 32px;
  text-transform: none !important;
}

.error {
  top: 101% !important;
}

.label {
  display: flex;
  align-items: center;
}


.button {
  margin-right: 10px;
}

.cropContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  background: #efefef;
}

.content {
  flex-grow: 1;
  position: relative;
}

.controls {
  height: 100px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.galleryHeaderButtonContainer {
  padding: 5px;
  cursor: pointer;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.galleryHeaderButtonIcon {
  fill: #000;
  width: 34px;
  height: 34px;
}

.galleryHeaderButtonContainer:hover .galleryHeaderButtonIcon {
  fill: #222;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99999;
  background: #fff;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.closeIcon .galleryHeaderButtonIcon {
  width: 22px;
  height: 22px;
}
