@import '@sass-variables';

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #efefef;
}


.header,.right,.headerInnerWrapper,.titleWrapper {
  display: flex;
  align-items: center;
  height: 100%;
}
.header {
  justify-content: center;
  min-height: 65px;
  width: 100%;
  position: relative;
  background: #fff;
  padding: 10px 0;
  box-sizing: border-box;
  box-shadow: 0 1px 5px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  z-index: 99;
}

.titleWrapper,.leftContent {
  padding: 0 10px;
}

.headerInnerWrapper {
  height: 100%;
  width: 1336px;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.right > *:not(:first-child) {
  margin-left: 15px;
}



.title {
  font-size: 25px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}

.content {
  position: relative;
  width: 1336px;
  max-width: 100%;
  flex-grow: 1;
  padding-bottom: 20px;
  margin-top: 25px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.left {
  flex-grow: 1;
}

.right {
  flex-grow: 1;
  justify-content: space-around;
}

.leftContent {
  order: 2;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 1;
  flex-wrap: nowrap;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
}

.leftContent > * {
  font-size: 16px;
  flex-grow: 1;
}

.leftContent > * {
  margin: 2px 0;
}

.menuWrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 45px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
}

.backLink {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  border: 1px solid $purple;
  display: flex;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.backIcon {
  height: 18px;
  display: block;
}

.dropdown > *:not(:last-child) {
  margin-bottom: 5px;
}

.mobileSubmitButton {
  position: fixed;
  bottom: 0;
  width: 100vw;
  left: 0;
  align-items: flex-end;
  border-radius: 0;
  z-index: 99;
  height: 80px;
  padding-right: 20px;
}

.mobileSubmitButton > * {
  font-size: 16px !important;
}

@media (min-width: 1024px){
  .dropdown > *:not(:last-child) {
    margin-bottom: 0;
  }
  .header {
    min-height: 70px;
  }
  .title {
    justify-content: center;
  }
  .left {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  .titleWrapper {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .leftContent {
    border-left: 1px solid #eee;
    border-top: none;
    margin-left: 35px;
    padding: 0 0 0 35px;
    flex-grow: 1;
    width: auto;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-top: 0;
  }
  .leftContent > * {
    padding-right: 60px;
    font-size: 17px;
    width: auto;
    flex-grow: 0;
    margin: 0;
  }
  .leftContent > *:not(:first-child) {
    margin-top: 0;
  }
  .leftContent > input,.leftContent > select {
    width: 300px;
  }
  .leftContent > input:not(:first-child),.leftContent > select:not(:first-child) {
    margin-top: 0;
    margin-left: 20px;
  }
  .right {
    flex-grow: 0;
    order: 3;
  }
  .backLink {
    height: 32px;
    width: 32px;
    margin-right: 22px;
  }
  .backIcon {
    height: 22px;
  }
}
