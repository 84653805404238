@media (min-width: 1024px){
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 800px;
  }

  .container > * {
    flex-grow: 1;
  }

  .container > *:not(:first-child) {
    margin-left: 10px;
  }
}
