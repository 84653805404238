@import '@sass-variables';

.linkWrapper,.link,.link::after {
  display: flex;
  align-items: center;
}

.link {
  text-decoration: none;
  color: transparent;
  width: 100%;
  justify-content: center;
  white-space: nowrap;
  position: relative;
}

.link::after {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  white-space: nowrap;
  justify-content: center;
  color: #b9b9b9;
  font-size: inherit;
}

.link.active::after {
  color: $purple;
  font-weight: bold;
}
