@import '@sass-variables';

.container {
  position: relative;
  width: 100%;
}
.cameraIcon {
  width: 36px;
  height: 36px;
  position: absolute;
  bottom: -14px;
  background: $purple;
  border-radius: 50%;
}

.hidden {
  position: absolute;
  visibility: hidden;
}

.dropzone {
  background-color: #dadada;
  border: 1px solid $purple;
  border-radius: 4px;
  margin-bottom: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;
}

.image {
  width: 100%;
  height: 100%;
}

.dropzone.active,.dropzone::active,.dropzone::focus {
  outline: none;
  border: 1px solid $purple;
}

.requirements {
  margin-bottom: 32px;
  text-transform: none !important;
}

.error {
  top: 101% !important;
}

.placeholder,.dropzone,.fileImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.placeholder {
  margin: 10px 0;
}

.placeholderHeader {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 10px;
}

.fileImage {
  width: 68px;
  max-width: 80%;
}

.fileName {
  font-size: 18px;
  font-weight: bold;
}

.or {
  margin: 6px 0;
}

.label {
  display: flex;
  align-items: center;
}


.button {
  margin-right: 10px;
}
