.container {
  padding: 14px 10px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.container:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.header {
  display: flex;
  justify-content: space-between;
}

.buttons {
  display: flex;
}

.buttons > *:not(:first-child) {
  margin-left: 10px;
}
.title {
  font-weight: bold;
  font-size: 15px;
}

.subtitle {
  font-size: 13px;
}
.text {
  font-size: 16px;
  padding: 18px 0 12px 0;
}

.note {
  font-style: italic;
}
