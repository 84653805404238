.container {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 10px;
  margin-bottom: 24px;
  max-width: 500px;
}

.container > *:first-child {
  display: flex;
  align-items: center;
}

.value {
  font-size: 16px;
  padding: 0 14px;
  width: 100%;
}
