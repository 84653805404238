@import '@sass-variables';

.title {
  font-size: 20px;
}
.subtitle {
  margin: 5px 0;
}
.subtitle a {
  color: $purple;
}
