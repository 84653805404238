.container {
  padding: 0 14px 0 14px;
}

.container,.flex,.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  align-items: flex-start;
  width: 100%;
}

.title {
  font-size: 22px;
  font-weight: 500;
  padding: 12px 0;
  color: #000;
  width: 100%;
  box-sizing: border-box;
}

.noPadding {
  padding: 0;
}

.noPadding .title {
  padding: 12px 14px;
}

@media (min-width: 1024px){
  .container {
    padding: 0;
  }
  .title,.noPadding .title {
    padding: 20px 0;
  }

  .flex {
    flex-direction: row;
  }
  .content {
    max-width: none;
  }
  .flex > * {
    box-sizing: border-box;
  }
  .flex > *:first-child {
    padding-right: 25px;
  }
  .flex > *:last-child {
    padding-right: 25px;
  }
  .flex > * {
    width: 50%;
  }
}
