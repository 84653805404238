.container {
  margin: 0 auto;
  width: 800px;
}
.button {
  margin: 20px 0 40px 0;
  width: 100%;
}
.fileWrapper {
  margin-bottom: 20px;
}

.header {
  font-size: 25px;
  font-weight: 300;
  line-height: 2;
}

.link {
  color: #1e73be;
}
.headerTwo {
  font-size: 25px;
  font-weight: 300;
  line-height: 2;
}
.text {
  line-height: 1.8;
}

.section {
  margin: 20px 0;
}

.sectionHeader {
  font-weight: 700;
}
@media (min-width: 1024px){
  .container {
    display: flex;
  }
  .container > :first-child {
    margin-right: 45px;
  }
  .fileWrapper {
    margin-bottom: 0;
  }
  .row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 800px;
  }

  .row > * {
    min-width: 50px;
  }

  .row > *:first-child {
    width: 45% !important;
  }

  .row > *:nth-child(2) {
    width: 24% !important;
  }

  .row > *:last-child {
    width: 27% !important;
  }

  .row > *:not(:first-child) > *:first-child {
    min-width: 25px !important;
  }
  .button {
    width: auto;
  }
}
