@import "@sass-variables";

.container {
  background: $purple;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 5px 0 10px;
}

.innerWrapper {
  display: flex;
  align-items: center;
  width: 1336px;
  height: 100%;
  max-width: 100%;
  justify-content: space-between;
}

.content {
  height: 100%;
  justify-content: space-between;
  position: relative;
  flex-grow: 1;
  align-content: end;
}

.content,
.links > *,
.logo {
  display: flex;
  align-items: center;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo > img {
  height: 50px;
}
.mobileLogo {
  height: 80px;
}
.links {
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80vw;
  left: -80vw;
  box-sizing: border-box;
  background: $purple;
  top: 0;
  bottom: 0;
  border-bottom: 4px solid $purple;
  transition: all 0.2s;
  z-index: 9998;
}

.links > * {
  font-size: 18px;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 20px 0;
  border-bottom: 1px solid transparent;
  color: transparent;
  justify-content: center;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  box-sizing: border-box;
  text-transform: capitalize;
  position: relative;
  transition: all 0.2s linear;
  float: left;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 999;
}
.overlay.open {
  background: rgba(0, 0, 0, 0.4);
  display: block;
}

.links.open {
  left: 0;
}

.links > *::after {
  content: attr(title);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: inherit;
}

.links > *.active {
  border-bottom-color: #e8e8e8;
}

.dropdown {
  display: none;
}

@media (min-width: 1024px) {
  .mobileOnly {
    display: none;
  }
  .container {
    height: 110px;
    padding: 0 45px;
  }
  .links {
    align-items: center;
    flex-direction: row;
    position: relative;
    width: 100%;
    background: transparent;
    left: 0;
    border-bottom: none;
    z-index: inherit;
  }
  .logo {
    height: 100%;
  }
  .logo > img {
    height: 60px;
  }
  .links > * {
    border-bottom-width: 5px;
    font-size: 16px;
    height: 100%;
    width: 100px;
    margin: 0;
  }

  .dropbtn {
    color: white;
    text-decoration: none;
  }

  .dropdown {
    display: flex;
    position: relative;
  }

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    top: 80px;
  }

  .dropdownContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdownContent a:hover {
    background-color: #f1f1f1;
  }

  .dropdown:hover .dropdownContent {
    display: block;
  }
}
@media (min-width: 1441px) {
  .links {
   // margin-left: 30px;
  }
  .links > * {
    font-size: 16px;
  }
  .links > *:not(:first-child) {
  //  margin-left: 30px;
  }
}
