@import '@sass-variables';

.container {
  position: fixed;
  top: 62px;
  background: #fff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  right: 5px;
  left: 5px;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  padding: 5px 22px;
  border-radius: 20px;
  cursor: default;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: #4D4D4D;
}
.infoButton {
  font-weight: bold;
  font-family: serif;
  font-size: 17px;
  width: 32px;
  height: 32px;
  text-align: center;
  position: absolute;
  right: 14px;
  line-height: 32px;
  border-radius: 50%;
  cursor: pointer;
  color: #4D4D4D;
}

.indicatorPanel {
  padding: 0;
}

.indicatorPanelContent {
  padding: 5px 22px;
}
.infoButton:hover {
  color: #000;
  background-color: #f6f6f6;
}
.flags {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 12px 25px 12px;
}
.flagField {
  margin: 5px;
}


.actions {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding: 14px 28px 18px 0;
  border-top: 1px solid rgba(77, 77, 77,0.15);
}

.cancel {
  color: #4D4D4D;
  cursor: pointer;
  font-size: 16px;
}

.cancel:hover {
  color: #000;
}

.apply {
  color: $purple;
  margin-left: 65px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
}

.apply:hover {
  color: $purple;
}

.indicatorButtonWrapper {
  margin-left: 6px;
  position: relative;
}

.panelButton {
  height: 34px;
  width: 34px;
  background: $purple;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid $purple;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
  flex-shrink: 0;
  cursor: pointer;
}

.panelButton img {
  width: 20px;
  height: 20px;
}
.slider {
  width: 100%;
  height: 3px;
  box-sizing: border-box;
  border-radius: 999px;
  display: flex;
  align-items: center;
  margin: 25px 0;
}
.track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
  background-color: #4D4D4D;
}


.thumb {
  height: 27px;
  line-height: 27px;
  width: 27px;
  font-size: 14px;
  text-align: center;
  background-color: $purple;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
}

.thumb,.thumb:active,.thumb:focus {
  border: none;
  outline: none;
}

.modalTitle {
  font-size: 17px;
  font-weight: bold;
  color: #4D4D4D;
  border-bottom: 1px solid #4D4D4D;
  line-height: 1.5;
  margin-bottom: 10px;
}

.modalInfo {
  font-size: 16px;
  line-height: 1.6;
  color: #4D4D4D;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  max-height: 95vh;
  overflow: auto;
}

.modal::-webkit-scrollbar {
  width: 8px;
}

.modal::-webkit-scrollbar-track {
  background: none;
}

.modal::-webkit-scrollbar-thumb {
  background: rgba(77,77,77,0.5);
  border-radius: 6px;
}

.modal .modalSection {
  width: 100%;
  flex-shrink: 0;
  margin: 12px 0;
}

@media (min-width: 540px) {
  .container {
    width: 446px;
  }
}

@media (min-width: 1024px) {
  .panelButton {
    height: 38px;
    width: 38px;
  }
  .modal {
    max-width: 740px !important;
  }
  .indicatorButtonWrapper {
    margin-left: 10px;
  }
  .container {
    position: absolute;
    top: 54px;
    right: 0;
    left: auto;
    max-height: none;
    overflow-y: hidden;
  }
  .container.left {
    left: 0;
    right: auto;
  }
}
