.headerItems {
  width: 50% !important;
  padding-right: 0;
}
.boldText {
  margin: 10px;
  font-weight: bold;
}
.chart {
  width: 100%;
  display: inline-block;
}
.chart2 {
  width: 50%;
  display: inline-block;
}
.dateWidth {
  width: 40%;
  display: inline-block;
}
.tabs {
  display: flex;
  flex-direction: "row";
  column-gap: 10px;
}
