.content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonWrapper {
  display: none;
}

.file {
  height: 400px;
}

.fileDropzone {
  height: 80vw;
}

.fileDropzoneWidth {
  width: 80vw;
}

.center {
  width: 80vw;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start {
  align-items: flex-start;
}

.switch {
  margin-top: 20px;
}

@media (min-width: 1024px) {
  .buttonWrapper {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 40px 0;
    width: 100%;
  }
  .content {
    flex-direction: row;
  }
  .center {
    width: 200px;
  }
  .switch {
    justify-content: flex-start;
    margin-top: 0;
  }
  .fileDropzoneWidth {
    width: 200px;
  }
  .fileDropzone {
    height: 200px;
  }
  .content > *:last-child {
    flex-grow: 1;
    padding-left: 40px;
    box-sizing: border-box;
  }
}
