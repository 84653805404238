@import '@sass-variables';

.container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 18px 0;
}

.header {
  display: flex;
  justify-content: center;
}

.title {
  font-size: 19px;
  color: #fff;
  width: 1336px;
  max-width: 100%;
  font-weight: 500;
  padding: 10px 14px;
  display: flex;
  align-items: center;
  background: $purple;
  box-sizing: border-box;
}

.margin {
  margin-bottom: 14px;
}

@media (min-width: 1024px) {
  .fixed {
    left: calc((100vw - 1353px)/2);
    right: calc((100vw - 1353px)/2);
  }
}
