.wrapper > * {
  display: block;
}
.container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.input {
  height: auto;
  width: auto;
}

.label {
  font-size: 15px;
}
