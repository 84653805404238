@import '@sass-variables';

.container {
  background: $purple;
  color: #fff;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  padding: 0 12px;
  height: 50px;
  border-radius: 25px;
  min-width: 150px;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
}

.unclickable {
  cursor: default;
}
.container.medium {

  min-width: 100px;
  height: 38px;
  border-radius: 19px;
}

.container.action {
  justify-content: space-between;
}

.container.action > img {
  margin-left: 10px;
}

.center {
  margin: 0 auto;
}


.secondary {
  background: transparent;
  color: $purple;
  border: 1px solid $purple;
}

.container.light {
  background: #5f47a1;
}

.secondary.light {
  background: transparent;
  color: #5f47a1;
  border-color: #5f47a1;
}

.red {
  background: $rose;
}
.disabled {
  background: #C1C1C1;
  cursor: default;
}


.container .text {
  font-size: 14px;
}

.container.medium .text {
  font-size: 11px;
}

.container.form {
  min-width: 130px;
  height: 35px;
}

.container.small {
  min-width: 96px;
  height: 30px;
  padding: 0 10px;
}

.container.small .text {
  font-size: 12px;
  text-transform: capitalize;
}
.container.xs {
  min-width: 78px;
  height: 26px;
  padding: 0 6px;
}

.container.xs .text {
  font-size: 11px;
  text-transform: capitalize;
}

.container.form .text {
  text-transform: capitalize;
}
.container.action > img {
  display: block;
  width: 24px;
}

@media (min-width: 1024px){
  .container {
    padding: 0 22px;
    min-width: 190px;
  }
  .container.medium {
    height: 42px;
    border-radius: 23px;
    min-width: 170px;
  }
  .container.xs {
    min-width: 80px;
    height: 26px;
    padding: 0 8px;
  }

  .container.xs .text {
    font-size: 12px;
    text-transform: capitalize;
  }
}

@media (min-width: 1441px){
  .container {
    min-width: 210px;
  }

}
