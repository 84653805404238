.container {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  color: #000;
  font-size: 20px;
}
