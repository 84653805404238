

.button {
  width: 100%;
  margin-bottom: 15px;
}

.button > *,.text,.text > * {
  color: #fff;
}

.text {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
