.dateContainer {
  margin-left: 10px;
}
.boldText {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: bold;
}
.medInput {
  width: 175px !important;
}