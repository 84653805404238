.flex {
  display: inline-flex;
  align-items: center;
}

.flex > *:first-child {
  flex-shrink: 0;
}

.flex > *:not(:first-child) {
  margin-left: 20px;
}
