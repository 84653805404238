@import '@sass-variables';

.link,.link * {
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 1.6;
}

.margin {
  margin-top: 35px;
}

.link {
  border-top: 1px solid $purple;
  padding: 18px 0;
}

.link a {
  font-weight: bold;
}

.terms {
  text-align: center;
  color: #fff;
  font-size: 12px;
  margin-top: 8px;
}

.terms a {
  color: #fff;
}
