.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.logo {
  width: 200px;
}

.header {
  margin: 0 0 10px 0;
  font-size: 42px;
  color: #000;
  text-align: center;
  text-decoration: none;
}

.text {
  text-align: center;
  font-size: 18px;
  margin: 0 0 30px 0;
}



.badgeWrapper img {
  height: 50px;
  width: auto;
  margin: 10px;
}
