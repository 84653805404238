.margin {
  margin-top: 5px;
}

.filters {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
}

@media (min-width: 1024px) {
  .filters {
    flex-wrap: nowrap;
  }
  .margin {
    margin-top: 0;
    margin-left: 20px;
  }
}
