.imageWrapper {
  width: 90px;
}

.image {
  height: 90px;
  width: 90px;
  margin-right: 10px;
  background: #a7a7a7;
}
