.container {
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container.containerDragging,.container:hover {
  background: #ddd;
}

.image,.dropzone:hover,.dropzone.active {
  background: #ddd;
}

.dropzone,.image {
  width: 322px;
  min-height: 429px;
  position: relative;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px dashed #ddd;
  cursor: pointer;
  flex-grow: 1;
}

.dropzone:hover,.dropzone.active {
  border: 2px dashed #bbb;
}



.mobileDropzone {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #fff;
  box-sizing: border-box;
}

.dropzone,.mobileDropzone {
  margin-bottom: 16px;
}

.list > * {
  margin-bottom: 16px;
}
.image > img {
  width: 100%;
  height: 100%;
}


.listWrapper,.list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.listWrapper {
  padding-bottom: 60px;
}

.banner {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.circle {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  color: #fff;
  border-radius: 50%;
  margin-left: 10px;
}



@media (min-width: 1024px) {
  .list > *:not(:nth-child(4n+1)),.marginLeft {
    margin-left: 16px;
  }

  .listWrapper,.list {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .listWrapper {
    padding-bottom: 0;
  }
  .deleteWrapper {
    display: none;
    cursor: pointer;
  }

  .image:hover .deleteWrapper {
    display: flex;
  }
}
