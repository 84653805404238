
.container > * {
  color: #fff;
  text-decoration: underline;
  font-weight: bold;
}

.container {
  color: #fff;
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}
