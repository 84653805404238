.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 12%;
  text-align: center;
  box-sizing: border-box;
}

.text {
  margin: 10px 0 20px 0;
}
