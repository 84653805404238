.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after,
.gm-style .poi-info-window div,
.gm-style .poi-info-window a {
  color: #4D4D4D;
}

.gm-style .poi-info-window a {
  text-decoration: underline;
}
