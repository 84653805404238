.container {
  color: #000;
  text-transform: capitalize;
  display: block;
  font-size: 15px;
  width: 100%;
}

@media (min-width: 1024px) {
  .container {
    max-width: 160px;
    min-width: 160px;
    margin-right: 10px;

  }
}
.secondary {
  max-width: 100%;
  margin-right: 0;
  margin-bottom: 5px !important;
}
