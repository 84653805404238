.label {
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: bold;
}

.subHeading {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: medium;
  color: gray;
}

.unconfirmedEmail {
  display: flex;
  gap: 8px;
  width: 80%;
}