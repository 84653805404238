@import '@sass-variables';

.header > *,.row > * {
  display: table-cell;
  vertical-align: middle;
}

.header > *,.row > * {
  padding: 8px;
  box-sizing: border-box;
  white-space: nowrap;
}

.header > * {
  font-size: 12px;
  height: 45px;
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
}

.header > *:not(:first-child),.row > *:not(:first-child) {
  padding-left: 6px;
}

.row {
  color: #000;
  text-decoration: none;
  transition: background 0.2s linear;
}

a.row:hover {
  background: #fcfcfc
}
.row > * {
  min-height: 55px;
  position: relative;
  font-size: 12px;
  border-bottom: 1px solid #ccc;
}
.container {
  display: table;
  width: 100%;
}
.wrapper {
  overflow-x: auto;
  width: 100%;
}
.row,.header {
  display: table-row;
}

.addBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}


@media (min-width: 1024px){
  .header > *:not(:first-child),.row > *:not(:first-child) {
    padding-left: 20px;
  }
  .header > *,.row > * {
    padding: 6px 0;
  }
  .header > * {
    font-size: 15px;
    height: 55px;
  }
  .row > * {
    min-height: 70px;
    font-size: inherit;
  }
}

@media (min-width: 1441px){

  .row > * > * {
    font-size: 16px;
  }
}
