@import '@sass-variables';

.flex,.switch {
  display: flex;
  align-items: center;
}

.flex {
  flex-wrap: wrap;
}

.flex {
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
}
.flex > *:first-child {
  flex-shrink: 0;
}

.flex > *:not(:first-child) {
  margin-left: 0;

}
.switch {
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
  box-sizing: border-box;
  margin-right: 20px;
  flex-shrink: 0;
}

.button {
  margin: 5px 0;

}
.button:hover {
  background: $purple !important;
  color: #fff !important;
}

.switchLabel {
  white-space: nowrap;
  max-width: none;
  width: auto;
}

.title {
  display: flex;
  align-items: center;
}

.title > *:first-child {
  flex-shrink: 0;
}

.title > *:not(:first-child) {
  margin-left: 20px;
}
.subtitle {
  margin: 5px 0;
}

@media (min-width: 1024px) {
  .switch {
    width: auto;
  }
  .flex {
    flex-direction: row;
    align-items: center;
  }
  .flex > *:not(:first-child) {
    margin-left: 20px;
  }
}
