@import '@sass-variables';

.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  margin-bottom: 8px;
  max-width: 800px;
}

.container > *:first-child {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}


.textarea {
}

.error {
  left: 0 !important;
  white-space: nowrap;
}

.inputWrapper {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputWrapper > * {
  flex-shrink: 0;
}


.container.secondary {
  display: block;
  margin-bottom: 16px;
  max-width: 100%;
}

.container.round input, .container.round select {
  border: none;
  border-bottom: 1px solid #e3e3e3;
  padding-left: 2px;
  font-size: 16px;
  padding: 0 2px 0 22px;
  border-radius: 21px;
}

.required {
  color: $rose;
  margin-left: 3px;
}

.sublabel {
  font-size: 14px;
  color: #777;
}

.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.row > *:first-child {
  max-width: 160px;
  min-width: 160px;
  margin-right: 10px;
}

.row > .inputWrapper {
  flex-grow: 0;
  width: auto;
}
@media (min-width: 1024px) {
  .container {
    flex-direction: row;
    margin-bottom: 24px;
  }
  .inputWrapper {
    width: auto;
    display: flex;
    align-items: center;
  }
  .container > *:first-child {
    margin-bottom: 0;
  }


}
