
.button {
  display: flex;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon {
  width: 24px;
  height: 24px;
}

.button:hover {
  background: #dadada;
}

.content > * {
  padding: 14px 14px;
  background: #fff;
  font-size: 16px;
  white-space: nowrap;
  transition: all .2s ease-in-out;
  cursor: pointer;
  display: block;
  color: #000;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
}

.content > *:not(:last-child) {
  border-bottom: 1px solid #dfdfdf;
}


.content > *:active,.content > *:focus,.content > *:hover {
  background: #dfdfdf;
}
