.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 80px;
}

.text {
  margin: 40px 0 25px 0;
  font-size: 18px;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.margin {
  margin-top: 50px;
}


.badgeWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.badgeWrapper img {
  height: 50px;
  width: auto;
  margin: 10px;
  border: 1px solid #fff;
  border-radius: 2px;
}
