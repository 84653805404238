.container {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  transform: scale(0.6);
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  z-index: 9999;
  margin: 0;
  overflow: visible; }
  .container.active .inner,
  .container.active .inner::before,
  .container.active .inner::after {
    background-color: #fff; }

.box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.inner {
  display: block;
  top: 50%;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  margin-top: -2px; }
  .inner, .inner::before, .inner::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .inner::before, .inner::after {
    content: "";
    display: block; }
  .inner::before {
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    top: -10px; }
  .inner::after {
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    bottom: -10px; }



.container.active .inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .container.active .inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .container.active .inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }
