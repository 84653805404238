@import '@sass-variables';

.filters {
  position: absolute;
  z-index: 9999;
  top: 10px;
  left: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  max-width: 500px;
}

.filters.mapLabel input,.filters.nearMeLabel input {
  display: none;
}

.fetcherWrapper {
  position: absolute;
  top: 50%;
  z-index: 999;
}

.fetcher {
  margin: 0 !important;
}

.nearMeButton {
  margin-left: 6px;
}

.noBorder {
  border-top: none !important;
}

.noPadding > * {
  padding: 5px 0 !important;
}

.bold {
  font-weight: 500;
}

.icon {
  height: 18px;
  width: auto;
  position: relative;
  top: 3px;
}

.break {
  margin: 8px 0;
}

@media (min-width: 1024px) {
  .filters {
    left: 20px;
  }
  .nearMeButton {
    margin-left: 10px;
  }
}
