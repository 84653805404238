@import '@sass-variables';

.container {
  color: #4D4D4D;
  padding: 0 15px;
  max-width: 340px;
}
.content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textWrapper {
  text-align: center;
}

.name {
  font-size: 19px;
  font-weight: 500;
  line-height: 1.4;
  white-space: normal;
  margin-bottom: 5px;
}

.street {
  font-size: 14px;
}
.charter {
  font-size: 14px;
  margin-top: 5px;
}

.email {
  color: #4D4D4D;
  text-decoration-color: #4D4D4D;
  display: inline-block;
  margin-top: 2px;
  font-size: 14px;
}

.indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 8px 0 8px 0;
}

.flag {
  margin: 5px 10px 5px 0;
  cursor: default;
  flex-shrink: 0;
}

.story {
  font-size: 14px;

  line-height: 1.4;
  max-height: 200px;
  font-style: italic;
  min-height: 50px;
  margin: 15px 0;
  text-align: center;
}

.flag:hover,.flag:active,.flag:focus {
  filter: none;
}

.imageWrapper {
  width: 168px;
  height: 224px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}
.largeImageWrapper {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
}

.imageNumber {
  position: absolute;
  top: 4px;
  right: 4px;
  color: #fff;
  font-size: 12px;
  z-index: 999;
}



.image {
  animation-name: fadeIn;
  animation-duration: 0.1s;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  display: block;
}
.infoImage {
  max-width: 100% !important;
  width: 100%;
  height: 100%;
}
.largeImage {
  max-width: 100% !important;
  object-fit: contain;
}
.readmore {
  cursor: pointer;
  color: $purple;
  font-size: 14px;
}
.readmore:hover,.readmore:active,.readmore:focus {
  text-decoration: underline;
}

.slider {
  height: 100%;
}

.gallery {
  padding: 65px 10px;
  justify-content: center;
}

@media (min-width: 1024px) {
  .filters {
    left: 20px;
  }
  .nearMeButton {
    height: 38px;
    width: 38px;
    margin-left: 10px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
