@import '@sass-variables';

.searchWrapper {
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
}

.input {
  border: none;
  font-size: 16px;
  padding: 0 10px;
  flex-grow: 1;
  min-width: auto;
}
.inputWrapper,.selectWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.selectContainer {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  height: 100%;
}

.selectWrapper {
  width: 65px;
  border-right: 1px solid #e3e3e3;
  cursor: pointer;
  font-size: 14px;
  color: #4D4D4D;
  font-weight: 500;
  padding: 0 4px 0 12px;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: 50%;
}

.inputWrapper {
  flex-grow: 1;
  transition: width 100ms linear;
  justify-content: flex-end;
  padding-right: 6px;
}

.searchBtn {
  height: 34px;
  width: 34px;
  background: $purple;
  color: #fff;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid $purple;
  box-sizing: border-box;
  border-radius: 22px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

}

.searchWrapper.nearMeLabel .inputWrapper,.searchWrapper.mapLabel .inputWrapper {
  width: 40px;
  padding-left: 0;
}
.searchWrapper.nearMeLabel,.searchWrapper.mapLabel {
  padding: 0;
}
.searchWrapper.mapLabel .selectWrapper,.searchWrapper.nearMeLabel .selectWrapper {
  border: none;
}

.searchText {
  margin-bottom: 2px;
  margin: 5px;
  font-size: 15px;
}

.searchIcon {
  color: #fff;
  transform: rotate(-45deg);
  font-size: 26px;
  margin: 5px;
}

.searchBtn.active {
  width: 98px;
  transition: width 50ms linear 50ms;
}


.slider {
  display: flex;
  align-items: center;
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  border: 1px solid #4D4D4D;
  border-radius: 999px;
  margin: 0 0 10px 0;
  position: relative;
}
.track {
  top: 0;
  bottom: 0;
  border-radius: 999px;
}


.thumb {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  background-color: $purple;
  border-radius: 999px;
  cursor: grab;
}

.thumb,.thumb:active,.thumb:focus {
  border: none;
  outline: none;
}

.mark {
  color: #4D4D4D;
  z-index: 99;

  font-size: 13px;
  text-align: center;
  width: 25%;
  cursor: pointer;
}


.mark.white {
  color: #fff;
  font-weight: 500;
}

@media (min-width: 540px) {
  .container {
    width: 320px;
    left: 5px !important;
    right: auto !important;
  }
}

@media (min-width: 1024px) {
  .searchWrapper {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    height: 50px;
  }
  .selectWrapper {
    width: 90px;
    font-size: 14px;
    padding: 0 24px;
    background-image: url(../../../../../assets/images/dropdown-gray.svg);
    background-position-y: calc(50% + 1px);
  }
  .searchBtn {
    height: 38px;
    width: 38px;
  }
  .input {
    font-size: 16px;
  }
  .inputWrapper {
    width: 500px;
    padding: 0 5px 0 10px;
  }

}
